export type Pagination = {
    page: number
    perPage: number
    perPageOptions: number[]
}

export function usePagination(options?: {
    perPage?: number
}) {
    const pagination = ref({
        page: 1,
        perPage: options?.perPage || 5,
        perPageOptions: [5, 10, 20, 50],
    })

    return {
        pagination,
    }
}
